<script>
import Layout from "@/router/website-template/main";
import appConfig from "@/app.config";

import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
/**
 * Galeri Component
 */
export default {
  page: {
    title: "Galeri",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout
  },
  data() {
    return {
      title: "Galeri",
      items: [
        {
          text: "Beranda",
          href: "/",
        },
        {
          text: "Galeri",
          active: true,
        },
      ],

      id: '99caddf8-7fa1-421a-a7ab-a04e9b56fff5',
      index_galeri: this.$route.params.id-1,

      // variable Page
      nama_halaman: null,
      link_youtube_path: null,
      konten: null,
      gambar_halaman_path: null,
      kategori: null,
      meta_content: [],
      meta_content_db: 0,
      link_dev: process.env.VUE_APP_BACKEND_URL,
      optionsKategori: [],
      kategoriSelected: null,
    };
  },
  mounted() {
    let self = this;
    self.getDataGaleri();

    //galeri kategori
    var config_data_skema_sertifikasi = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/website-galeri-kategori-active",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config_data_skema_sertifikasi)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          self.optionsKategori = response_data_fix.referensi;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });


  },
  methods: {
    getDataGaleri() {
      let self = this;
      // get data halaman berdasarkan id
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "halaman-website/" + self.id,
        params: {

        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          var response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            var data_edit = response_data_fix;
            self.nama_halaman = data_edit.nama_halaman;
            self.gambar_pendukung_path = data_edit.gambar_pendukung;
            self.gambar_pendukung_full = process.env.VUE_APP_BACKEND_URL + data_edit.gambar_pendukung;
            self.konten = data_edit.konten;
            self.gambar_halaman_path = data_edit.gambar_halaman;
            self.gambar_halaman_full = process.env.VUE_APP_BACKEND_URL + data_edit.gambar_halaman;
            self.kategori = data_edit.kategori?.nama_kategori_halaman;

            // META CONTENT
            if (data_edit.meta_content) {
              var json_response_meta_content = JSON.parse(
                data_edit.meta_content
              );
              let clear_data_json_response_meta_content = [];
              $.each(
                json_response_meta_content,
                function (indexInArray, valueOfElement) {
                  clear_data_json_response_meta_content.push({
                    kategori: valueOfElement.kategori,
                    gambar_pendukung: valueOfElement.gambar_pendukung,
                    keterangan: valueOfElement.keterangan,
                    tanggal_kegiatan: valueOfElement.tanggal_kegiatan,
                  });
                }
              );
              
              self.meta_content = clear_data_json_response_meta_content[self.index_galeri];
              self.meta_content_db = self.meta_content.length;
            }

            Swal.close();
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.close();
        });
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row mt-5">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center" style="margin-top:50px;">
              <h1>GALERI</h1>
            </div>
            <div class="row mt-5">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card p-2" style="width: 100%;">
                      <h5 class="card-title">{{ meta_content.kategori?.nama_galeri_kategori }}</h5>
                      <img style="height: 550px; width: 100%;" :src="link_dev + meta_content.gambar_pendukung">
                      <div class="card-body">
                        <h5 class="card-title">{{ meta_content.tanggal_kegiatan }}</h5>
                        <div v-html="meta_content.keterangan"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Line with Data Labels chart -->

          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
